 /deep/ .upload {
  height: 40px !important;
}
 /deep/ .upload .el-upload {
  height: 40px !important;
  border: none;
}
.fileList {
  margin-top: 40px;
}
.fileList .file-item {
  display: flex;
}
.fileList .file-item .el-button {
  margin-left: 10px;
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.errorInfo {
  display: flex;
}
.errorInfo .success {
  color: #5C6BE8;
}
.errorInfo .error {
  margin-left: 20px;
  color: red;
}
.errorInfo .el-button {
  margin-left: 20px;
}
